import '../scss/style.scss';
import phoneValidaten from "./components/phone-validaten";
import dateBadge from './components/course-date-badge';

// Init Validate Phone
window.addEventListener('DOMContentLoaded', () => {
  phoneValidaten.mounted();
});

/**
 * Detailseite Lehrgang -> add dependent body css
 */
$(document).ready(function () {
  if ($('section.page-course-detail').length > 0) {
    // $('body').css('overflow', 'visible');
    $('body .content-wrapper').attr('style', 'overflow: visible !important');

  }

  if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    $('body').addClass('agent-ios');
  }

  // Init Mask
  $(":input").inputmask();

  window.checkForInboundLocationHash();
});

$(function (e) {
  /**
   * General
   */
  var isMobileLayout = $(window).width() < 992;
  var isEditmode = $('body').hasClass('editmode');
  var equalizedLayoutState = "";

  // sliders data
  // note: js bug it declared in one line...
  var topicSwipersArray = [];
  var blogTeaserSwipersArray = [];
  var thesisTeaserSwipersArray = [];
  var doubleCtaSwipersArray = [];
  var imageGallerySwipersArray = [];
  var homepageSwiperArray = [];
  var thesisDetailImageSlider = [];
  var thesisDetailImageModalSlider = [];

  var topicSwipers = $('.swiper-container.topic-swiper');
  var blogTeaserSwipers = $('.swiper-container.blog-teaser-swiper');
  var thesisTeaserSwipers = $('.swiper-container.thesis-teaser-swiper');
  var doubleCtaSwipers = $('.swiper-container.double-cta-swiper');
  var imageGallerySwipers = $('.swiper-container.image-gallery-swiper');
  var homepageSwiper = $('.swiper-container.homepage-swiper');
  var thesisDetailImageSwiper = $('.b-thesis-detail-image-slider .swiper-container');
  var thesisDetailImageModalSwiper = $('.b-thesis-detail-image-modal-slider .swiper-container');

  // init sliders, equalize boxes
  initLoadedPage();
  toggleLoadingOverlayPosition();

  const sliders = new window.Sliders();
  sliders.reInitSliders();

  // on resize init / destroy slider & mouse events
  $(window).on('resize', function () {
    isMobileLayout = $(window).width() < 992;

    /**
     * Init Destroy swipers on resize
     */

    sliders.reInitSliders();
    setTimeout(()=> {
      sliders.reInitSliders();
    }, 800);

    // topic type
    if (topicSwipersArray.length < 1 && $(this).width() < 992) {

      // attempt to init
      initTopicSwipers();

    } else if (topicSwipersArray.length > 0 && $(this).width() >= 992) {
      $(topicSwipersArray).each(function () {
        this.destroy(true, true);
      });
      topicSwipersArray = [];
    }

    // thesis type
    if (thesisTeaserSwipersArray.length < 1 && $(this).width() < 992) {

      // attempt to init
      initThesisTeaserSwipers();

    } else if (thesisTeaserSwipersArray.length > 0 && $(this).width() >= 992) {
      $(thesisTeaserSwipersArray).each(function () {
        this.destroy(true, true);
      });
      thesisTeaserSwipersArray = [];
    }

    // double-cta type
    if (doubleCtaSwipersArray.length < 1 && $(this).width() < 992) {

      // attempt to init
      initDoubleCtaSwipers();

    } else if (doubleCtaSwipersArray.length > 0 && $(this).width() >= 992) {
      $(doubleCtaSwipersArray).each(function () {
        this.destroy(true, true);
      });
      doubleCtaSwipersArray = [];
    }

    // lehrgang-tables curtain
    toggvarableCurtain();

    // blog-detail -> share buttons
    pinShareButtons();

    // lehrgang-detail -> sticky H1
    pinStickyHeadline();

    // Equalize layout
    if (!$("body").hasClass("editmode")) {
      equalizeLayout();
    }

    stretchImagegallerySlides();
    detectStickyElements();
    toggleLoadingOverlayPosition();
  });

  /**
   * Icon Tooltip fix
   */

  $('.icon').hover(function() {
    var em = $(this).find('.icon-tooltip');
    if (em.length > 0) {
      var overflowInPx = (em.offset().left + em.width() + 22) - $(window).width();

      if (overflowInPx > 0) {
        em.css('left', 'calc(-50% - ' + overflowInPx + 'px)');
        em.find('span.arrow').css('left', 'calc(50% - 8px + ' + overflowInPx + 'px)');
      } else {
        em.css('left', '-50%');
        em.find('span.arrow').css('left', 'calc(50% - 8px)');
      }
    }
  });

  /**
   * Add padding in footer if sticky element is present
   */
  function detectStickyElements() {
    if ($(window).width() < 992 && $('.buttons-block').length > 0) {
      $('#footer').css('padding-bottom', '72px');
    } else if ($(window).width() < 992 && $('.sticky-cta').length > 0) {
      $('#footer').css('padding-bottom', '78px');
    } else {
      $('#footer').css('padding-bottom', '0');
    }
  }

  /**
   * Nav Toggles for Mobile
   */

  $('.mobile-toggle .on-open')
    .add('.mobile-toggle .on-close')
    .add('.mobile-toggle .burger')
    .on('click', function () {

      $('#nav').toggleClass('nav-open');
      $('body').toggleClass('nav-open');

      // spec. for extranet page
      if ($('body').hasClass('page-extranet')) {
        $('#nav .mobile-toggle').removeClass('shadow');
      }
    });

  $('.level-1 .level-1-item .level-1-title').on('click', function () {
    if (isMobileLayout) {
      var _this = $(this).parents('.level-1-item');
      if (_this.hasClass('open')) {
        _this.removeClass('open');

      } else {
        if ($('.level-1-item.open').length > 0) {
          $('.level-1-item.open').removeClass('open');
          setTimeout(function () {
            _this.addClass('open');
          }, 0); // animation time

        } else {
          _this.addClass('open');
        }
      }
    }
  });

  $('.level-1 .level-2-item .level-2-title').on('click', function () {
    if (isMobileLayout) {
      var _this = $(this).parents('.level-2-item');
      if (_this.hasClass('open')) {
        _this.removeClass('open');

      } else {
        if ($('.level-2-item.open').length > 0) {
          $('.level-2-item.open').removeClass('open');
          setTimeout(function () {
            _this.addClass('open');
          }, 500);

        } else {
          _this.addClass('open');
        }
      }
    }
  });

  // if active item in level1 -> toggle open
  $('.level-1-item.active').addClass('open');

  var mouseleaveTimer;
  // Desktop Nav Togglers
  $('li.level-1-item .level-1-title.has-children .title-content').on('click', function () {
    if (!isMobileLayout) {
      var $level1Item = $(this).parents('.level-1-item');

      $level1Item.toggleClass('desktop-open');
      $('main').toggleClass('in-background');


      $('li.level-1-item').not($level1Item).removeClass('desktop-open');
      $('main').removeClass('in-background');

      if ($('li.level-1-item.desktop-open').length > 0) {
        $('#nav').addClass('z-index');
        $('main').addClass('in-background');
      } else {
        $('#nav').removeClass('z-index');
        $('main').removeClass('in-background');
      }
    }
  });

  $('li.level-1-item').on('mouseenter', function () {
    clearTimeout(mouseleaveTimer);
  });
  $('li.level-1-item').on('mouseleave', function () {

    var $this = $(this);

    if (!isMobileLayout) {
      mouseleaveTimer = setTimeout(function () {
        $this.removeClass('desktop-open');
        $('main').removeClass('in-background');
        $('#nav').removeClass('z-index');
      }, 300);
    }
  });

  $('#nav .menu-main').on('click', function (e) {
    var $this = $(e.originalEvent.target);

    if ($this.prop('tagName') === 'A') {

      if ($(this).parents('.page-extranet').length > 0) {
        $('#nav .level-2-item').removeClass('active');
        $this.closest('.level-2-item').addClass('active');

      } else {
        $('#nav *').removeClass('open');
        $('#nav *').removeClass('active');
        $this.closest('.level-1-item').addClass('open');
        $this.closest('.level-2-item').addClass('open');
        $this.closest('.level-3-item').addClass('active');
      }
    }
  });

  /**
   * General Load Animation
   */
  $(document).on('click', 'a', function (e) {
    if (isEditmode) {
      return;
    }

    if (e.shiftKey || e.ctrlKey || e.metaKey) {
      return;
    }

    var hostname = "//" + window.location.hostname,
      extranet = "/extranet",
      target = e.currentTarget,
      href = target.href,
      attrHref = $(this).attr("href");

    if (attrHref === "#" || attrHref.match("^#")) {
      e.preventDefault();

      var hash = $(this).attr("href");

      if (hash.length > 1 && !~hash.indexOf("=") && $(hash).length > 0) {
        $('html, body').animate({
          scrollTop: $(hash).offset().top - 30
        }, 1800, 'swing');
      }

      if (!$(target).hasClass('overlay-call')) {
        window.location.hash = hash;
      }

      return;
    }

    if ($(this).hasClass("no-url") || ($(this).hasClass("no-mobile-url") && isMobileLayout)) {
      e.preventDefault();
      return;
    }

    if ($(this).attr("data-loading-handler") == "default") {
      return;
    }

    // check for internal link
    if (~href.indexOf(hostname)) {
      // check if current site or target is in extranet (has to be loaded like normal page)
      if (~href.indexOf(extranet) || ~window.location.pathname.indexOf(extranet) || target.target === "_blank") {
        return;
      } else if ($(this).hasClass("landing-inbound")) {
        return;
      }

      e.preventDefault();
      loadContent(href, $(this).data('load-section'));
    }
  });

  var previousUrl = location.href.split('#')[0];

  // load new content on browser back and forward
  window.onpopstate = function (e) {
    window.go4history.popstate();

    if (isEditmode) {
      return;
    }

    if (window && window.history && window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }

    var href = e.target.location.href.split('#')[0];

    if (previousUrl.match("^" + href + "#") || href.match("^" + previousUrl + "#") || previousUrl == href) {
      return;
    }

    loadContent(href, $(this).data('load-section'), false, e.type);
  };

  var loadContent = function (href, contentName, isInitLoad, eventType) {

    // check if already loading or not
    if (!$('body').hasClass('animating-load-screen')) {
      isInitLoad = isInitLoad || false;
      eventType = eventType || false;

      if (isInitLoad && window.initLoadDone) {
        return;
      }

      if (isInitLoad) {
        window.initLoadDone = true;
      }

      // make sure badge is gone before animation starts
      dateBadge.forceFadeOut();

      // hide nav if open
      if (isMobileLayout) {
        $('body').removeClass('nav-open');
        $('.nav-main').removeClass('nav-open');
      } else {
        $('.level-1-item').removeClass('desktop-open');
        $('#nav').removeClass('z-index');
        $('main').removeClass('in-background');
      }

      // lock body
      $('body').addClass('animating-load-screen');

      var newScreen = $('.idle-screen'),
        oldScreen = $('.active-screen'),
        progressBar = $('.progress-bar'),
        loadingOverlay = $('.loading-overlay');

      // append empty progress bar
      progressBar.empty();
      progressBar.append('<div class="progress"></div>');

      var progress = $('.progress-bar .progress');

      // simulate loading
      progress.addClass('loading');
      loadingOverlay.addClass('show');

      // curtain animation
      var additionalStyles = $('body').hasClass('page-extranet') ? "width: 100vw; left: -409px;" : "";
      var curtain = '<div class="curtain" style="position: absolute; left: 0; right: 0; top: 0; height: 300vh; z-index: 999; ' + additionalStyles + '"></div>';

      // here "load" new page content or just animate if initial page load
      var newContent = $(curtain);

      var finishLoading = function (newContent) {
        // append loaded content to background screen
        loadingOverlay.removeClass('show');
        newScreen.append(newContent);

        $('.curtain').css('height', '0');

        // when content is finished loading and ready to animate:
        newScreen.addClass('content-loaded');
        oldScreen.addClass('fade-out');

        window.scrollTo(0, 0);

        // timeout for animation
        setTimeout( () => {
          oldScreen.removeClass('active-screen');
          oldScreen.removeClass('fade-out');
          newScreen.removeClass('idle-screen');
          newScreen.addClass('active-screen');
          newScreen.removeClass('content-loaded');
          oldScreen.addClass('idle-screen');
          oldScreen.empty();

          // unlock body
          $('body').removeClass('animating-load-screen');
          oldScreen.removeClass('bg-darken');

          // Init Phone Validation
          phoneValidaten.mounted();

          // timeout for progress bar animation and -> then fade out
          setTimeout(() => {
            progress.removeClass('loading');
          }, 800);

          if (window.location.hash) {
            var hash = window.location.hash;
            if (!~hash.indexOf("=") && $(hash).length > 0) {
              $('html, body').animate({
                scrollTop: $(hash).offset().top - 370 - 30
              }, 2000, 'swing');
            }
          }
        }, 1000);
      };


      if (!isInitLoad) {
        $.ajax({
          url: href,
          type: 'get',
          success: function (data) {
            var $newContent = $(data),
              $metaDescription = $("meta[name='description']"),
              shareTags = [
                'og:type',
                'og:url',
                'og:title',
                'og:description',
                'twitter:url',
                'twitter:title',
                'twitter:description'
              ];

            newContent = $newContent.find(".content-screen.idle-screen").html();

            if (eventType !== "popstate") {
              previousUrl = location.href;
              window.history.pushState({}, "", href);
            }

            if (newContent) {
              var title = $newContent.filter("title").text(),
                description = $newContent.filter("meta[name=description]").attr("content");

              $metaDescription.remove();
              if (description) {
                $('head').append('<meta name="description" content="' + description + '">');
              }
              $("head title").text(title);

              // replace share tags
              removeShareTags();

              if ($newContent.find(".page-blog-detail").length == 0) {
                $.each(shareTags, function (index, shareTag) {
                  var newShareContent = $newContent.filter("meta[property='" + shareTag + "']").attr("content");
                  $('head').append('<meta property="' + shareTag + '" content="' + newShareContent + '">');
                });
              }

              if (typeof gtag !== 'undefined' && typeof window.googleTag !== 'undefined') {
                gtag('config', window.googleTag, {
                  page_title: $('title').text(),
                  page_location: window.location.pathname
                });
              }

              // load js again
              $("*").off();
              $(window).off();
              $(document).off();

              $.getJSON('/build/entrypoints.json', function (entrypoints) {
                const entrypoint = entrypoints.entrypoints['js/app'];
                entrypoint.js.forEach(function (jsUrl) {
                  $.getScript(jsUrl);
                });
              });

              finishLoading(newContent);
            } else {
              location.reload();
              return;
            }
          },
          error: function (data) {
            this.success(data.responseText)
          }
        });
      } else {
        finishLoading();
      }
    }

    detectStickyElements();
  };

  /**
   * Initial Content Load and Load Animation
   */
  if (!isEditmode) {
    loadContent(e, $(this).data('load-section'), true);
  }

  /**
   * Init / Destroy Slides on load
   */
  function checkAndInitAllSwipers() {
    topicSwipersArray = [];
    topicSwipers = $('.swiper-container.topic-swiper');
    blogTeaserSwipers = $('.swiper-container.blog-teaser-swiper');
    thesisTeaserSwipers = $('.swiper-container.thesis-teaser-swiper');
    doubleCtaSwipers = $('.swiper-container.double-cta-swiper');
    imageGallerySwipers = $('.swiper-container.image-gallery-swiper');
    homepageSwiper = $('.swiper-container.homepage-swiper');

    if (topicSwipers.length > 0 && isMobileLayout) {
      initTopicSwipers();
    }

    if (blogTeaserSwipers.length > 0) {
      initBlogTeaserSwipers();
    }

    if (thesisTeaserSwipers.length > 0 && isMobileLayout) {
      initThesisTeaserSwipers();
    }

    if (doubleCtaSwipers.length > 0 && isMobileLayout) {
      initDoubleCtaSwipers();
    }

    if (imageGallerySwipers.length > 0 && $("body.editmode").length < 1) {
      initImageGallerySwipers();

      setTimeout(function () {
        imageGallerySwipers.each(function () {
          imageGalleryRecalc($(this));
        });
      }, 5000);
    }

    if (homepageSwiper.length > 0) {
      initHomepageSwiper();
    }
  }

  function initTopicSwipers() {
    topicSwipers.each(function () {
      var topicSwiper = new Swiper($(this), {
        pagination: {
          el: '.swiper-pagination',
        },
        loop: true
      });
      topicSwipersArray.push(topicSwiper);
    });
  }

  function initBlogTeaserSwipers() {
    blogTeaserSwipersArray = [];
    blogTeaserSwipers.each(function () {
      var blogTeaserSwiper = new Swiper($(this), {
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        loop: false,
      });
      blogTeaserSwipersArray.push(blogTeaserSwiper);
    });
  }

  function initThesisTeaserSwipers() {
    thesisTeaserSwipers.each(function () {
      var thesisTeaserSwiper = new Swiper($(this), {
        pagination: {
          el: '.swiper-pagination',
        },
      });
      thesisTeaserSwipersArray.push(thesisTeaserSwiper);
    });
  }

  function initDoubleCtaSwipers() {
    doubleCtaSwipers.each(function () {
      var doubleCtaSwiper = new Swiper($(this), {
        pagination: {
          el: '.swiper-pagination',
        },
      });
      doubleCtaSwipersArray.push(doubleCtaSwiper);
    });
  }

  function initImageGallerySwipers() {
    imageGallerySwipers.each(function () {
      var swiperEm = $(this);

      var imageGallerySwiper = new Swiper(swiperEm, {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChangeTransitionEnd: function () {
            imageGalleryRecalc($(swiperEm));
          }
        }
      });
      imageGallerySwipersArray.push(imageGallerySwiper);

      // update slides counter
      swiperEm.find('.slide-legend .counter').each(function (index) {
        $(this).text((index + 1) + " / " + swiperEm.find('.swiper-slide').length);
      });

      // update gallery slides heights acc. to img height
      stretchImagegallerySlides();

    });
  }

  var isInitial = true;
  var iterationIndex = 0;

  function imageGalleryRecalc(swiperEm) {
    var activeSlide = swiperEm.find('.swiper-slide.swiper-slide-active');
    var activeSlideContentHeight = $(swiperEm).find('.swiper-slide.swiper-slide-active .slide-content').height(); // + 60px ?

    activeSlide.height(activeSlideContentHeight);
    activeSlide.css('height', activeSlideContentHeight + 'px !important');

    activeSlide.parents('.swiper-container').height(activeSlideContentHeight);
    activeSlide.parents('.swiper-container').css('height', activeSlideContentHeight + 'px !important');
  }

  function stretchImagegallerySlides() {
    $('.b-image-gallery').find('.swiper-slide').each(function () {
      var $slide = $(this);
      var slideHeight = $slide.find('img').height();

      $slide.height(slideHeight + 60);
    });
  }

  function initHomepageSwiper() {
    iterationIndex = 0;
    isInitial = true;

    if (window.sliderViewsCount <= 1) {
      addMobileSliderButtons();
      return;
    }

    homepageSwiper.each(function () {
      var swiperEm = $(this);

      var swiper = new Swiper(swiperEm, {
        speed: 700,
        watchSlidesProgress: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        loop: true,
        on: {
          slideChangeTransitionStart: function () {
            updateBulvar();
          }
        }
      });

      homepageSwiperArray.push(swiper);
    });

    // in mobile-> append buttons
    addMobileSliderButtons();
  }

  function addMobileSliderButtons() {
    var btns = $('.homepage-slider .swiper-slide').find('a.btn-primary');


    btns.each(function () {

      var em = $(this).clone();
      em.removeClass('desktop-only');
      em.addClass('mobile-only');

      em.attr('data-index', $(this).parents('.swiper-slide').data('index'));

      // only if not appended yet
      if ($('.homepage-slider .animated-pagination a.btn-primary[data-index="' + $(this).parents('.swiper-slide').data('index') + '"]').length < 1) {
        $('.homepage-slider .animated-pagination').append($(em));
      }
    });


    $('.homepage-slider .animated-pagination a.btn-primary[data-index="1"]').addClass('active');
  }

  function updateMobileSliderButton(index) {
    $('.animated-pagination a.btn-primary.active').removeClass('active');

    $($('.animated-pagination').find('a.btn-primary[data-index="' + index + '"]')).addClass('active');
  }

  // init bulvar
  function updateBulvar() {
    iterationIndex = parseInt($('.homepage-slider .swiper-slide-active').data('index'));

    updateMobileSliderButton(iterationIndex);

    if (iterationIndex === 1) {
      $('.homepage-slider .animated-pagination .bar span.progress').each(function () {
        $(this).css('width', '0%');
        $(this).parent().removeClass('blocked');
        $(this).parent().addClass('enabled');

        $('.homepage-slider .animated-pagination .bar:nth-child(1)').removeClass('pre-enabled');
        $('.homepage-slider .animated-pagination .bar:nth-child(2)').addClass('pre-enabled');
        $('.homepage-slider .animated-pagination .bar:nth-child(3)').addClass('pre-enabled');
        $('.homepage-slider .animated-pagination .bar:nth-child(4)').addClass('pre-enabled');
      });
    } else if (iterationIndex === 2) {
      $('.homepage-slider .animated-pagination .bar:nth-child(1)').addClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').addClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').removeClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').removeClass('blocked');

      $('.homepage-slider .animated-pagination .bar:nth-child(1)').removeClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').removeClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').removeClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').removeClass('enabled');

      $('.homepage-slider .animated-pagination .bar:nth-child(1)').addClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').removeClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').addClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').addClass('pre-enabled');
    } else if (iterationIndex === 3) {
      $('.homepage-slider .animated-pagination .bar:nth-child(1)').addClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').addClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').addClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').removeClass('blocked');

      $('.homepage-slider .animated-pagination .bar:nth-child(1)').removeClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').removeClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').removeClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').removeClass('enabled');

      $('.homepage-slider .animated-pagination .bar:nth-child(1)').addClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').addClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').removeClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').addClass('pre-enabled');
    } else if (iterationIndex === 4) {
      $('.homepage-slider .animated-pagination .bar:nth-child(1)').addClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').addClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').addClass('blocked');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').addClass('enabled');

      $('.homepage-slider .animated-pagination .bar:nth-child(1)').removeClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').removeClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').removeClass('enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').removeClass('blocked');

      $('.homepage-slider .animated-pagination .bar:nth-child(1)').addClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(2)').addClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(3)').addClass('pre-enabled');
      $('.homepage-slider .animated-pagination .bar:nth-child(4)').removeClass('pre-enabled');
    }

    showProgress(iterationIndex);
  }

  function showProgress(index) {

    var elem = $($('.homepage-slider .animated-pagination .bar')[index - 1]);
    var width = 1;

    var autoplay = isInitial ? 3700 : 4700; // initial: -- speed
    isInitial = false;

    var autoplayTime = 4700 / 100;  // time = sum of speed + delay

    var id = setInterval(frame, autoplayTime);

    function frame() {

      if (width >= 100) {
        clearInterval(id);

        elem.removeClass('pre-enabled');
      } else {
        width++;
        if (elem.hasClass('enabled') && !elem.hasClass('pre-enabled')) {
          elem.find('span.progress').css('width', width + '%');
        } else if (elem.hasClass('pre-enabled')) {
          elem.find('span.progress').css('width', '0%');
          clearInterval(id);
        }
      }
    }
  }

  // Slider pagination
  $('.homepage-slider .animated-pagination .bar').on('click', function () {
    homepageSwiperArray[0].slideTo($(this).data('slide'));
  });

  /**
   * Equalizer functions
   */
  function equalizeLayout(isInitLoad) {
    isInitLoad = isInitLoad || false;

    // timeout to calc DOM correctly
    var timeoutMs = 0;

    if (isInitLoad) {
      equalizedLayoutState = isMobileLayout ? 'm' : 'd';
      timeoutMs = 100;
    }

    setTimeout(function () {
      // 1. Topic Boxes -> equalize to highest
      window.equalizeByElement($('.idle-screen .topic-slides .slide-content'));
      window.equalizeByElement($('.active-screen .topic-slides .slide-content'));

      // 2. Topic Column Headlines
      window.equalizeByElement($('.topic-column .topic-headline'));

      // 3. Double-CTA Boxes
      if (!isMobileLayout) {
        window.equalizeByElement($('.double-cta-slides .swiper-slide'), true, $('.double-cta-slides .swiper-slide .slide-content'));
      } else {
        $('.double-cta-slides .swiper-slide .slide-content').css('height', 'auto');
      }

      // 4. Blog-Übersicht
      if (!isMobileLayout) {
        window.equalizeByElement($('.teasers-showcase .teaser .highlight-box .inner'));
      }

      // Highlight boxes
      if (!isMobileLayout) {
        window.equalizeByElement($('.b-highlight-boxes .inner .box'));
      } else {
        $('.b-highlight-boxes .inner .box').css('height', 'auto');
      }
    }, timeoutMs);
  }

  /**
   * Init-Page function
   *
   * Checks loaded HTML for sliders and elements that need to be equalized
   */

  // todo: ...
  function initLoadedPage() {

    // Bugfix for Windows
    var BrowserDetect = {
      init: function () {
        this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
        this.version = this.searchVersion(navigator.userAgent)
          || this.searchVersion(navigator.appVersion)
          || "an unknown version";
        this.OS = this.searchString(this.dataOS) || "an unknown OS";
      },
      searchString: function (data) {
        for (var i = 0; i < data.length; i++) {
          var dataString = data[i].string;
          var dataProp = data[i].prop;
          this.versionSearchString = data[i].versionSearch || data[i].identity;
          if (dataString) {
            if (dataString.indexOf(data[i].subString) != -1)
              return data[i].identity;
          } else if (dataProp)
            return data[i].identity;
        }
      },
      searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index == -1) return;
        return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
      },
      dataBrowser: [
        {
          string: navigator.userAgent,
          subString: "Chrome",
          identity: "Chrome"
        },
        {
          string: navigator.userAgent,
          subString: "OmniWeb",
          versionSearch: "OmniWeb/",
          identity: "OmniWeb"
        },
        {
          string: navigator.vendor,
          subString: "Apple",
          identity: "Safari",
          versionSearch: "Version"
        },
        {
          prop: window.opera,
          identity: "Opera"
        },
        {
          string: navigator.vendor,
          subString: "iCab",
          identity: "iCab"
        },
        {
          string: navigator.vendor,
          subString: "KDE",
          identity: "Konqueror"
        },
        {
          string: navigator.userAgent,
          subString: "Firefox",
          identity: "Firefox"
        },
        {
          string: navigator.vendor,
          subString: "Camino",
          identity: "Camino"
        },
        {		// for newer Netscapes (6+)
          string: navigator.userAgent,
          subString: "Netscape",
          identity: "Netscape"
        },
        {
          string: navigator.userAgent,
          subString: "MSIE",
          identity: "Explorer",
          versionSearch: "MSIE"
        },
        {
          string: navigator.userAgent,
          subString: "Gecko",
          identity: "Mozilla",
          versionSearch: "rv"
        },
        { 		// for older Netscapes (4-)
          string: navigator.userAgent,
          subString: "Mozilla",
          identity: "Netscape",
          versionSearch: "Mozilla"
        }
      ],
      dataOS: [
        {
          string: navigator.platform,
          subString: "Win",
          identity: "Windows"
        },
        {
          string: navigator.platform,
          subString: "Mac",
          identity: "Mac"
        },
        {
          string: navigator.userAgent,
          subString: "iPhone",
          identity: "iPhone/iPod"
        },
        {
          string: navigator.platform,
          subString: "Linux",
          identity: "Linux"
        }
      ]

    };

    BrowserDetect.init();
    if (BrowserDetect.OS == "Windows") {
      // quick windows fix
      $('span.webinar-icon').addClass('os-windows');
      $('.ort-tracking').addClass('os-windows');
      $('#nav').addClass('os-windows');
    }

    checkAndInitAllSwipers();
    animateFormLabels();
    window.initSelectDropdowns();
    dateBadge.mounted();

    if (!$("body").hasClass("editmode")) {
      equalizeLayout(true);
    }

    $('.b-image-gallery').each(function () {
      var $this = $(this);
    });
  };

  /**
   * Detailseite-Lehrgang Sticky H1 Animation
   */
  pinStickyHeadline();

  function pinStickyHeadline() {
    if ($('section').hasClass('page-course-detail') && !isMobileLayout) {
      var stickyTriggerHeight = 337 + 211; // height of nav + content margin-top + prev elements

      $(window).on('scroll', function () {
        if ($(window).scrollTop() >= stickyTriggerHeight) {

          //$('.sticky-curtain-wrapper').css('position', 'fixed');
          $('.sticky-curtain-wrapper').addClass('stuck');
          $('.info-icons').addClass('add-padding');
        } else {
          //$('.sticky-curtain-wrapper').css('position', 'relative');
          $('.sticky-curtain-wrapper').removeClass('stuck');
          $('.info-icons').removeClass('add-padding');
        }
      })
    }
  }

  /**
   * Blog-Detail - Sticky Share Buttons
   */
  pinShareButtons();

  function pinShareButtons() {
    if (($('section').hasClass('page-blog-detail') || $('section').hasClass('page-thesis-detail')) && !isMobileLayout) {
      var buttonsTriggerDistance = $('.post-header').height() + 91
        + parseInt($('.post-header').css('margin-bottom').slice(0, -2))
        + parseInt($('.content').css('margin-top').slice(0, -2))
        + $('#nav').height() + 3;

      $(window).on('scroll', function () {

        if ($(window).scrollTop() >= buttonsTriggerDistance) {
          $('.share-buttons').addClass('stuck');
        } else {
          $('.share-buttons').removeClass('stuck');
        }
      });
    }
  }

  /**
   * Accordion
   */
  $('.b-teko-accordion .accordion-item .accordion-title').on('click', function () {
    $(this).parents('.accordion-item').toggleClass('open');
  });

  /**
   * If on load fields are filled -> animate labels
   */
  function animateFormLabels() {
    $('input[type="text"]').each(function () {
      if ($(this).val() !== "") {
        $(this).addClass('animate-label');
      }
    });
    $('input[type="email"]').each(function () {
      if ($(this).val() !== "") {
        $(this).addClass('animate-label');
      }
    });
    $('input[type="number"]').each(function () {
      if ($(this).val() !== "") {
        $(this).addClass('animate-label');
      }
    });
    $('input[type="tel"]').each(function () {
      if ($(this).val() !== "") {
        $(this).addClass('animate-label');
      }
    });
    $('textarea').each(function () {
      if ($(this).val() !== "") {
        $(this).addClass('animate-label');
      }
    });
  }

  /**
   * Detailseite-Lehrgabng Akkordion-Tabellen
   */

  toggvarableCurtain();

  function toggvarableCurtain() {
    if ($('section').hasClass('page-course-detail')) {
      $('.scrollable-table thead').each(function () {
        var tableContentWidth = $(this).width();
        var containerWidth = $(this).parents('.table-wrapper').width();

        if (tableContentWidth > containerWidth) {
          $(this).parents('.table-wrapper').addClass('has-overflow');
        } else {
          $(this).parents('.table-wrapper').removeClass('has-overflow');
        }
      });
    }
  }

  /**
   * special behavior for course detail page
   */
  if ($(".idle-screen section.page-course-detail").length > 0 || $(".idle-screen section.page-overview").length > 0) {
    $("head title").text(window.seoTitle);

    $("meta[name='description']").remove();
    $("head").append('<meta name="description" content="' + window.seoDescription + '">');

    $("meta[property='og:title']").attr('content', window.seoTitle);
    $("meta[property='og:description']").attr('content', window.seoDescription);
    $("meta[property='twitter:title']").attr('content', window.seoTitle);
    $("meta[property='twitter:description']").attr('content', window.seoDescription);

    if ($("section.page-course-detail").length > 0) {
      $("link[rel='canonical']").remove();
      $("head").append('<link rel="canonical" href="' + window.courseCanonical + '">');

      $("meta[property='og:url']").attr('content', window.courseCanonical);
      $("meta[property='twitter:url']").attr('content', window.courseCanonical);
    }
  }
  if ($(".idle-screen section.page-course-detail").length === 0) {
    window.shortTitle = undefined;
  }

  /**
   * special behavior for thesis detail page
   */
  if ($("section.page-thesis-detail").length > 0 || $("section.page-overview").length > 0) {
    $("head title").text(window.seoTitle);

    $("meta[name='description']").remove();
    $("head").append('<meta name="description" content="' + window.seoDescription + '">');

    if ($("section.page-thesis-detail").length > 0) {
      $("link[rel='canonical']").remove();
      $("head").append('<link rel="canonical" href="' + window.courseCanonical + '">');
    }
  }

  if ($('.page-blog-detail').length > 0) {
    $("link[rel='canonical']").remove();
    $("head").append('<link rel="canonical" href="' + window.blogCanonical + '">');
  }

  /**
   * remove share tags from blog on other pages
   */
  function removeShareTags() {
    $("meta[property^='og:']").remove();
    $("meta[property^='article:']").remove();
    $("meta[property^='twitter:']").remove();
  }

  /**
   * Prevent Slide Drag when btn Clicked
   */
  $('.b-blog-teaser-slider').bind('touchstart touchmove touchend', function () {

  });

  $('.focuspoint').focusPoint({
    throttleDuration: 0
  });

  /**
   * Mobile Nav -> open active items
   */
  toggleMobileNavOpen();

  if (window.getUrlParam('workflow-unsubscribe')) {
    window.openOverlayByName('workflow-unsubscribe');
  }

  /** Location select in extranet **/
  var $locationSelect = $(".page-extranet .location-select");
  if ($locationSelect.length) {
    $locationSelect.on("click", function () {
      $(this).toggleClass("open");
    });

    // close dropdown when clicking anywhere but on the location select itself
    $(document).on('mousedown', function (e) {
      if (e && e.originalEvent && e.originalEvent.target) {
        var eventTarget = e.originalEvent.target,
          $target = $(eventTarget);

        if (!$target.hasClass("location-select") && !$.contains($locationSelect.get(0), e.originalEvent.target)) {
          $locationSelect.removeClass("open");
        }
      }
    });
  }

  /* Inbound Landing Page */
  $('html').on('click', '.close-landing', function () {
    $('body').removeClass('inbound-landing-page');
  });
});

function toggleMobileNavOpen() {
  var level3Child = $('.level-3-item.active');
  var level2Child = $('.level-2-item.active');

  if (level3Child.length > 0) {
    level3Child.parents('.level-2-item').addClass('open');
    level3Child.parents('.level-1-item').addClass('open');

  } else if (level2Child.length > 0) {
    level2Child.parents('.level-1-item').addClass('open');
  }
}

function toggleLoadingOverlayPosition() {
  let yOffset = 0;
  if ($('section.page-course-detail, section.page-location-detail').length && $(window).width() >= 992) {
    yOffset = '-170px';
  }
  $('.loading-overlay').css({
    'top': yOffset
  });
}

window.equalizeByElement = function (em, justifyChildren, childEm) {
  justifyChildren = justifyChildren || false;

  var maxHeight = 0;
  if (em.length > 1) {

    em.each(function () {
      var emChildren = $(this).children();
      var emHeight = 0;

      emChildren.each(function () {
        var _this = $(this);

        if ($(this).css('position') !== 'absolute') {
          emHeight += _this.height();
          emHeight += parseInt(_this.css('padding-top').slice(0, -2));
          emHeight += parseInt(_this.css('padding-bottom').slice(0, -2));
          emHeight += parseInt(_this.css('margin-top').slice(0, -2));
          emHeight += parseInt(_this.css('margin-bottom').slice(0, -2));
        }
      });

      maxHeight = emHeight > maxHeight ? emHeight : maxHeight;

    });

    if (maxHeight > 0) {
      if (justifyChildren && childEm.length > 0) {
        childEm.height(maxHeight);
      } else {
        em.height(maxHeight);
      }
    }
  }
}

function getUrlvars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
}

window.getUrlParam = function (parameter) {
  var urlparameter;

  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlvars()[parameter];
    if (urlparameter) {
      urlparameter = urlparameter.split('#')[0];
    }
  }

  return urlparameter;
}

window.elementInViewport = function (el) {
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  return (
    top >= window.pageYOffset &&
    left >= window.pageXOffset &&
    (top + height) <= (window.pageYOffset + window.innerHeight) &&
    (left + width) <= (window.pageXOffset + window.innerWidth)
  );
}

/**
 * Nav -> always show top when opened
 */

var savedScrollTop = 0;
$('.nav-main .toggle-btn').on('click', function () {
  if ($(this).parents('.nav-open').length > 0) {
    savedScrollTop = $(window).scrollTop();
    window.scrollTo(0, 0);
  } else {
    window.scrollTo(0, savedScrollTop);
  }
});

(function (event) {
  var objectId = this.object.id;

  if (confirm('Wollen Sie die Infoalass-Registrierung an Tocco übermitteln')) {
    var url = '/admin/createintocco?o=' + objectId,
      win = window.open(url, '_blank');

    if (win) {
      win.focus();
    }
  }
});
